//
//
//
//
//
//
//
export default {
  name: "autoRouterParent",
  data: function data() {
    return {
      title: "",
      showBack: true
    };
  },
  watch: {
    $route: function $route(value) {
      this.title = value.meta.title;
    }
  },
  created: function created() {
    this.title = this.$route.meta.title;
  },
  methods: {
    hiddenBack: function hiddenBack() {
      this.showBack = false;
    }
  }
};